import { Filters } from "../../index.service";

export const getCoordinates = (selectedFilter, hexData) => {
  const {
    q,
    r,
    s,
    qDeFi,
    qTreas,
    qbm,
    qnft,
    qens,
    rDeFi,
    rTreas,
    rbm,
    rnft,
    rens,
    sDeFi,
    sTreas,
    sbm,
    snft,
    sens,
  } = hexData;
  let coordinateQ = q;
  let coordinateR = r;
  let coordinateS = s;
  switch (selectedFilter) {
    case Filters.bigMoves:
      coordinateQ = qbm;
      coordinateR = rbm;
      coordinateS = sbm;
      break;
    case Filters.defi:
      coordinateQ = qDeFi;
      coordinateR = rDeFi;
      coordinateS = sDeFi;
      break;
    case Filters.nft:
      coordinateQ = qnft;
      coordinateR = rnft;
      coordinateS = snft;
      break;
    case Filters.treasury:
      coordinateQ = qTreas;
      coordinateR = rTreas;
      coordinateS = sTreas;
      break;
    case Filters.ens:
      coordinateQ = qens;
      coordinateR = rens;
      coordinateS = sens;
      break;
    default:
      coordinateQ = q;
      coordinateR = r;
      coordinateS = s;
      break;
  }

  return { q: coordinateQ, r: coordinateR, s: coordinateS };
};
