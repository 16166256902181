import React, { useEffect, useState } from "react";
import DashboardContainer from "./container";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../web3/connectors";
import { ChainId, Providers } from "../../web3/web3-constants";
import {
  filterHexByTime,
  Filters,
  generateHexSVGBase64,
  generateMap,
  uploadImage,
} from "./index.service";
import * as htmlToImage from "html-to-image";

const DashboardPage = ({ history, location }) => {
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [hexData, setHexData] = useState({});
  const [filteredHexData, setFilteredHexData] = useState({});
  const { library, chainId, account, activate, deactivate, active } =
    useWeb3React();
  const [isValidNetwork, setIsValidNetwork] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [twitterImageUrl, setTwitterImageUrl] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(Filters.home);

  useEffect(() => {
    const provider = window.localStorage.getItem("provider");
    if (provider) activate(connectors[provider]);
  }, []);

  useEffect(() => {
    let hexes = hexData.hexes ?? [];
    if (hexes.length > 0) {
      let filteredData = filterHexByTime(hexes, selectedFilter);
      console.log({ filteredData });
      setFilteredHexData({ ...hexData, hexes: filteredData });
    }
  }, [selectedFilter, hexData]);

  useEffect(() => {
    console.log({ filteredHexData });
  }, [filteredHexData]);

  useEffect(() => {
    console.log({ library, chainId, account, active });
    if (account) {
      setIsLoading(true);
      generateMap(account)
        .then((response) => {
          setIsLoading(false);
          setHexData(response[0]);
          console.log({ response });
        })
        .catch((error) => {
          setIsLoading(false);
          console.log({ error });
        });
    }
    setIsValidNetwork(chainId === ChainId.ethereumMainnet);
  }, [library, chainId, account, active]);

  const onClickConnectWallet = (e) => {
    e.preventDefault();
    setShowConnectModal(true);
  };
  const onClickShareHex = (e) => {
    e.preventDefault();
    // let node = document.getElementsByClassName("grid-container")[0];
    // console.log({ node });
    // htmlToImage
    //   .toPng(node)
    //   .then(function (dataUrl) {
    //     console.log({ dataUrl });
    //   })
    //   .catch(function (error) {
    //     console.error("oops, something went wrong!", error);
    //   });
    setTwitterImageUrl(
      "https://dotearth.blob.core.windows.net/dotearthbucket/glyphs/glyph-9954535.png"
    );
    setShowShareModal(true);
  };
  const closeConnectWalletModal = () => {
    setShowConnectModal(false);
  };
  const closeShareModal = () => {
    setTwitterImageUrl("");
    setShowShareModal(false);
  };
  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };

  const onClickMetaMask = () => {
    closeConnectWalletModal();
    activate(connectors.injected);
    setProvider(Providers.metaMask);
  };
  const onClickWalletConnect = () => {
    closeConnectWalletModal();
    activate(connectors.walletConnect);
    setProvider(Providers.walletConnect);
  };
  const onClickDisconnect = () => {
    window.localStorage.setItem("provider", undefined);
    document.body.className = "";
    deactivate();
    setHexData({});
    setIsValidNetwork(false);
  };

  const onClickHex = async (hex) => {
    const { fillURL } = hex;
    try {
      let pngData = await generateHexSVGBase64(hex);
      var strImage = pngData.replace(/^data:image\/[a-z]+;base64,/, "");
      let response = await uploadImage(strImage);
      console.log({ response });
    } catch (error) {
      console.log({ error });
    }
    return;
    setTwitterImageUrl(fillURL);
    setShowShareModal(true);
  };

  const onChangeFilter = (event) => {
    console.log("onChangeFilter");
    if (event instanceof Event) {
      setSelectedFilter(event.target.value);
    } else {
      setSelectedFilter(event);
    }
  };

  return (
    <DashboardContainer
      onClickConnectWallet={onClickConnectWallet}
      closeConnectWalletModal={closeConnectWalletModal}
      onClickMetaMask={onClickMetaMask}
      onClickWalletConnect={onClickWalletConnect}
      onClickShareHex={onClickShareHex}
      showConnectModal={showConnectModal}
      showShareModal={showShareModal}
      closeShareModal={closeShareModal}
      onClickDisconnect={onClickDisconnect}
      accountConnected={active}
      address={account}
      hexData={filteredHexData}
      isValidNetwork={isValidNetwork}
      isLoading={isLoading}
      onClickHex={onClickHex}
      twitterImageUrl={twitterImageUrl}
      onChangeFilter={onChangeFilter}
      selectedFilter={selectedFilter}
    />
  );
};

export default DashboardPage;
