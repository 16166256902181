import React, { useEffect, useState } from "react";
export const HexagonBackground = ({}) => {
  return (
    <div id="scene" data-relative-input="true">
      {/* <ul className="hexagon-grid-container">
        <li className="hexagon hexagon-green">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-green">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-green">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-green">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-green">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-green">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-green">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-green">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-blue">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-blue">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-blue">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-blue">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-blue">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-blue">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-blue">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-blue">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-yellow">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
        <li className="hexagon hexagon-red">
          <div className="hexagon-inner"></div>
        </li>
      </ul> */}
    </div>
  );
};
