import React, { useEffect } from "react";
import { HexGrid, Layout, Hexagon, Text, Pattern } from "react-hexgrid";
import "./react-grid-layout.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Filters } from "../../index.service";
import { getCoordinates } from "./index.service";
const ReactHexGridLayout = ({
  hexData,
  onClickHex,
  onChangeFilter,
  selectedFilter,
}) => {
  useEffect(() => {
    const { hexes } = hexData;
    (hexes ?? []).map((item, index) => {
      //this will make the image not blurry. Current package does not provide straight forward procedure to include this.
      let pattern = document.getElementById(`PAT-${index}`);
      if (pattern) {
        pattern.setAttribute("width", "100%");
        pattern.setAttribute("height", "100%");
      }
    });
  }, [hexData]);
  useEffect(() => {
    //Iterating through all the svg child elements and accumulation the minX, minY maxX, maxY to find the scale level of SVG.
    const svg = document.querySelector("svg.grid");
    const { xMin, xMax, yMin, yMax } = [...svg.children].reduce((acc, el) => {
      const { x, y, width, height } = el.getBBox();
      if (!acc.xMin || x < acc.xMin) acc.xMin = x;
      if (!acc.xMax || x + width > acc.xMax) acc.xMax = x + width;
      if (!acc.yMin || y < acc.yMin) acc.yMin = y;
      if (!acc.yMax || y + height > acc.yMax) acc.yMax = y + height;
      return acc;
    }, {});
    const viewbox = `${xMin} ${yMin} ${xMax - xMin} ${yMax - yMin}`;
    svg.setAttribute("viewBox", viewbox);
  });

  const {
    layoutHexagonSizeX,
    layoutHexagonSizeY,
    layoutOriginX,
    layoutOriginY,
    layoutSpacing,
    viewboxHeight,
    viewboxMinX,
    viewboxMinY,
    viewboxWidth,
    hexes,
  } = hexData;
  const hexagonSize = {
    x: layoutHexagonSizeX ?? 15,
    y: layoutHexagonSizeY ?? 15,
  };

  return (
    <React.Fragment>
      <TransformWrapper
        wheel={{ step: 0.01 }}
        maxScale={4}
        centerOnInit={true}
        doubleClick={{ disabled: true }}
        panning={{ disabled: false }}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            {/* <aside className="filterblock">
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small">Filter by </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={selectedFilter}
                  label="Time Frame"
                  onChange={onChangeFilter}
                >
                  <MenuItem value={Filters.all}>
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={Filters.month}>Month</MenuItem>
                  <MenuItem value={Filters.week}>Week</MenuItem>
                  <MenuItem value={Filters.hours}>24 hours</MenuItem>
                </Select>
              </FormControl>
            </aside> */}
            <aside className="zoom-btn-wrapper">
              <IconButton onClick={() => zoomIn()}>
                <ZoomInIcon />
              </IconButton>
              <IconButton onClick={() => zoomOut()}>
                <ZoomOutIcon />
              </IconButton>
            </aside>
            <TransformComponent>
              <div className="grid-container">
                <HexGrid
                  width={"100%"}
                  height={"100%"}
                  viewBox={`${viewboxMinX ?? 0} ${viewboxMinY ?? 0} ${
                    viewboxWidth ?? 100
                  } ${viewboxHeight ?? 100}`}
                >
                  {/* Main grid with bit hexagons, all manual */}
                  <Layout
                    size={hexagonSize}
                    flat={false}
                    spacing={layoutSpacing}
                    origin={{ x: layoutOriginX, y: layoutOriginY }}
                  >
                    {(hexes ?? []).map((hex, index) => {
                      const {
                        borderColorHex,
                        borderColorWidth,
                        fillText,
                        fillURL,
                      } = hex;
                      let { q, r, s } = getCoordinates(selectedFilter, hex);

                      return (
                        <>
                          <Hexagon
                            onClick={() => {
                              onClickHex(hex);
                            }}
                            q={q}
                            r={r}
                            s={s}
                            cellStyle={{
                              stroke: borderColorHex,
                              strokeWidth: borderColorWidth,
                            }}
                            fill={`PAT-${index}`}
                            id={`grid-identifier-${index}`}
                          >
                            {fillText && <Text>{fillText}</Text>}
                          </Hexagon>
                          {fillURL && (
                            <Pattern
                              id={`PAT-${index}`}
                              link={fillURL}
                              size={hexagonSize}
                            />
                          )}
                        </>
                      );
                    })}
                  </Layout>
                </HexGrid>
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </React.Fragment>
  );
};

export default ReactHexGridLayout;
